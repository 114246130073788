<template>
  <div class="d-flex justify-content-between align-items-center mt-2 mb-3">
    <div class="d-flex align-items-center">
      <router-link
        :to="{
          name: 'staff-page',
        }"
        class="main-tab__title"
      >
        Сотрудники
      </router-link>
      <router-link
        :to="{
          name: 'staff-roles-page',
        }"
        class="main-tab__title"
      >
        Роли
      </router-link>
    </div>

    <button
      class="profile__btn"
      data-bs-toggle="modal"
      :data-bs-target="$route.meta.topbarBtn.id"
    >
      {{ $route.meta.topbarBtn.title }}
    </button>
  </div>
</template>

<script>
export default {
  name: "profile-topbar",
};
</script>